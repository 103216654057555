import { servicePath } from "constants/defaultValues";
import { takeEvery, put } from "redux-saga/effects";
import { request } from "services/requests.services";

function* getQueryData(action) {
  try {
    const { page, userId, searchKeyword, type } = action.state;
    const data = yield request("POST", `${servicePath}/customerQueryDetails/getAllComplaintQuery?page=${page}&userId=${userId}`, { searchKeyword, type });
    yield put({ type: "GET_CUSTOMER_QUERT_DATA_SUCCESS", data: data });
  } catch (error) {
    console.log(error);
    yield put({ type: "GET_CUSTOMER_QUERT_DATA_FAILED", message: error.message });
  }
}

function* queryCount(action) {
  try {
    const { userId } = action.state;
    const { data } = yield request("POST", `${servicePath}/customerQueryDetails/getOpenQueries`, { userId });
    yield put({ type: "GET_OPEN_QUERT_DATA_SUCCESS", data: data });
  } catch (error) {
    yield put({ type: "GET_OPEN_QUERT_DATA_FAILED", message: error.message });
  }
}

function* getComQuery(action) {
  try {
    const { data } = yield request("POST", `${servicePath}/customerQueryDetails/getComplaintListQuery`, { ...action.state });

    yield put({ type: "GET_COMPLAINT_QURIES_SUCCESS", data: data });
  } catch (error) {
    yield put({ type: "GET_COMPLAINT_QURIES_FAILED", message: error.message });
  }
}

function* updateQuery(action) {
  try {
    const { ...other } = action.state;
    const { data } = yield request("POST", `${servicePath}/customerQueryDetails/addOrUpdate`, { ...other });
    yield put({ type: "UPDATE_COMPLAINT_QURIES_SUCCESS", data: data });
    // yield call(getQueryData, {state:{...currentState}})
  } catch (error) {
    // console.log(error)
    yield put({ type: "UPDATE_COMPLAINT_QURIES_FAILED", message: error.message });
  }
}

function* closeQuery(action) {
  try {
    const data = yield request("POST", `${servicePath}/customerQueryDetails/closeExecutiveQuery`, { ...action.state });

    yield put({ type: "CLOSE_COMPLAINT_QURIES_SUCCESS", data: data });
  } catch (error) {
    yield put({ type: "CLOSE_COMPLAINT_QURIES_FAILED", message: error.message });
  }
}

export default function* saga() {
  yield takeEvery("GET_CUSTOMER_QUERT_DATA", getQueryData);
  yield takeEvery("GET_OPEN_QUERT_DATA", queryCount);
  yield takeEvery("GET_COMPLAINT_QURIES", getComQuery);
  yield takeEvery("UPDATE_COMPLAINT_QURIES", updateQuery);
  yield takeEvery("CLOSE_COMPLAINT_QURIES", closeQuery);
}
